.content {
  height: calc(100% - 72px);
  position: relative;
  width: 100%;
  margin-top: 72px;
  box-sizing: border-box;
  background-color: "#E5E5E5";
}

.link {
  color: inherit;
  text-decoration: none;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.block {
  display: block;
}

.overflow-block {
  max-width: 100%;
  display: block;
}

button {
  max-width: 100%;
}

label,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
