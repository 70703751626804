body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wizard {
  max-width: 650px;
  width: 90%;
  margin: 4rem auto 0;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 35px;
  grid-template-areas:
    "content"
    "buttons";
}

.wizard__buttons-left {
  grid-area: left;
  float: left;
}

.wizard__buttons-right {
  grid-area: right;
  float: right;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  width: 8px;
  height: 8px;
  border: none;
  background: transparent;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.5);
}

html > ::-webkit-scrollbar {
  background-color: #cc0533;
}

html > ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
  margin-top: 72px;
  background-color: #f3f3f3;
}

hr {
  border: none;
  border-bottom: 1px solid #DEE1E6;
}

.react-datepicker__input-container {
  border: 2px solid rgb(117,117,117);
  border-radius: 4px;
  width: unset,
}

.react-datepicker__input-container > input {
  border: none;
  border-radius: 4px;
  line-height: 20px;
  padding: 6px;
  outline: none;
}